import React from "react";

const WorkingOnIt = () => {
  return (
    <div className="border border-orange-400 py-3 px-2 text-orange-400 rounded items-center text-center mb-5">
        Working on it...
    </div>
  );
};

export default WorkingOnIt;
